<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Users List'">
          <template v-slot:preview>
            <div>
              <b-tabs content-class="mt-3">
                <div class="row align-items-center mb-3">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center mb-0">
                        Show
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                        entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-9">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right d-block d-sm-block d-md-flex d-xl-flex d-md-flex"
                    >
                      <label class="d-inline-flex align-items-center mb-0">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                      <i
                        @click="refreshData"
                        class="fa fa-refresh refreshBtn ml-2 cursor-pointer"
                        aria-hidden="true"
                      ></i>
                      <!--                      <div class="ml-2">-->
                      <!--                        <button-->
                      <!--                          class="btn print excel-btn"-->
                      <!--                          @click="excelExport()"-->
                      <!--                        >-->
                      <!--                          Export to Excel-->
                      <!--                        </button>-->
                      <!--                        <button class="btn print" @click="csvExport(csvData)">-->
                      <!--                          Export to CSV-->
                      <!--                        </button>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
                <!-- End search -->
                <b-tab title="Users Registered Via Email" active>
                  <div class="card">
                    <div class="card-body p-0">
                      <div class="row mb-md-2"></div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                          :items="usersByEmail"
                          :fields="fields"
                          responsive="true"
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                        >
                          <!--                          <template v-slot:cell(status)="usersByEmail">-->
                          <!--                            <b-badge-->
                          <!--                              :class="-->
                          <!--                                usersByEmail.item.status === 'Active'-->
                          <!--                                  ? 'mr-1 badge-font cursor-pointer'-->
                          <!--                                  : 'mr-1 badge-font badge-pending cursor-pointer'-->
                          <!--                              "-->
                          <!--                              pill-->
                          <!--                              variant="success"-->
                          <!--                              >{{ usersByEmail.item.status }}-->
                          <!--                            </b-badge>-->
                          <!--                          </template>-->
                          <template v-slot:cell(Action)="usersList">
                            <div class="table-actions">
                              <a
                                href="javascript:void(0)"
                                class="reject-icon"
                                @click="deleteUsersList(usersList)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tabs>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 24,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      userList: [],
      DropDownMenu: false,
      uuid: "",
      usersByEmail: [],
      UsersByApple: [],
      fields: [
        { key: "unique_code", label: "Customer ID", sortable: true },
        { key: "join_date", label: "Join Date", sortable: true },
        { key: "name", label: "Customer Name", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "total_spent", label: "Total Spent", sortable: true },
        { key: "last_order", label: "Last Order", sortable: true },
        // { key: "status", label: "Status", sortable: true },
        { key: "Action", sortable: true }
      ]
    };
  },
  computed: {
    rows() {
      return this.usersByEmail.length;
    },
    csvData() {
      return this.usersByEmail.map(item => ({
        ...item
      }));
    }
  },
  components: {
    KTCodePreview
  },
  methods: {
    refreshData() {
      this.getAllUsers();
    },
    imageLoaderError(event) {
      return (event.target.src = "icontest.png");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllUsers() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/user")
        .then(response => {
          this.usersByEmail = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Users.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.usersByEmail);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Users.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    },
    deleteUsersList(user) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(this.$path + "/admin/user/" + user.item.uuid)
            .then(response => {
              this.loader = false;
              helpers.successMessages(this, response.data.message);
              let index = this.usersByEmail.findIndex(
                el => el.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.usersByEmail.splice(index, 1);
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.totalRows = this.usersByEmail.length;
    this.getAllUsers();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>

<style scoped>
.nav-tabs .nav-item a:active,
.nav-tabs .nav-item a:focus {
  color: #fff !important;
}
.nav-tabs .nav-item a.active {
  background-color: #fd683e !important;
  color: #fff !important;
}
</style>
